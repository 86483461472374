import * as Yup from 'yup';
import { AnySchema } from 'yup';
import IFormField from '../models/form-field.model';
import { IResaleAdditionalField, IResalePaymentMethodProvider } from '../models/resale.model';
import useTranslations from '../hooks/use-translations';
import getPrice from '../utils/get-price';

export function getResalePaymentMethodsField(
    paymentMethodProviders: IResalePaymentMethodProvider[]
): IFormField {
    const options: IFormField['options'] = paymentMethodProviders.map((provider) => {
        const shipmentPrice = provider.regularPrice.grossInteger - provider.finalPrice.grossInteger;
        const shipmentPriceDisplay = getPrice(shipmentPrice / 100);
        return {
            label: `${provider.name}: <span class="payment-final-price">${
                provider.finalPrice.grossDisplay
            }</span>${
                shipmentPrice > 0
                    ? ` <br><span class="payment-option-shipment-price">(uwzględniono koszt wysyłki ${shipmentPriceDisplay})</span>`
                    : ''
            }`,
            value: `${provider.paymentMethodId}`,
            enabled: true,
            hint: provider.hint,
        };
    });
    return {
        type: 'radio',
        name: 'paymentMethod.paymentMethodId',
        label: '',
        options: options,
    };
}

export function getResalePaymentMethodProviderAdditionalFields(
    paymentMethodProvider: IResalePaymentMethodProvider
): (IFormField & { columnSpan?: string })[] {
    const { fields } = paymentMethodProvider;
    if (!fields) return [];
    return fields.map((field) => {
        return {
            name: `paymentMethod.data.${field.name}`,
            label: `${field.label}${field.isRequired ? '*' : ''}`,
            placeholder: field.placeholder || '',
            type: field.type,
            columnSpan: field.columnSpan,
        };
    });
}

export function getSelectedPaymentMethod(
    selectedPaymentMethodId: number | string | undefined,
    paymentMethodProviders: IResalePaymentMethodProvider[]
) {
    if (!selectedPaymentMethodId) return;
    return paymentMethodProviders.find(
        (provider) => provider.paymentMethodId.toString() === selectedPaymentMethodId.toString()
    );
}

interface IGetResalePaymentMethodValidationSchemaConfig {
    selectedPaymentMethodProviderId?: number | string | undefined;
    paymentMethodProviders: IResalePaymentMethodProvider[];
    hasConsent?: boolean;
    t: ReturnType<typeof useTranslations<'ResalePaymentMethodForm'>>;
}

export function getResalePaymentMethodValidationSchema({
    selectedPaymentMethodProviderId,
    paymentMethodProviders,
    hasConsent,
    t,
}: IGetResalePaymentMethodValidationSchemaConfig) {
    const selectedShipmentMethod = getSelectedPaymentMethod(
        selectedPaymentMethodProviderId,
        paymentMethodProviders
    );
    const dataFields = selectedShipmentMethod?.fields;
    return Yup.object().shape({
        paymentMethod: Yup.object({
            paymentMethodId: Yup.string().required(t.fields.paymentMethodId.error.required),
            data:
                dataFields && dataFields.length > 0
                    ? Yup.object(getPartialResalePaymentMethodValidationSchema(dataFields, t))
                    : Yup.object(),
        }),
        ...(hasConsent
            ? { consent: Yup.boolean().oneOf([true], `${t.fields.consent.error.required}`) }
            : {}),
    });
}

export interface IResalePaymentMethodFormValues {
    paymentMethod: {
        paymentMethodId: string;
        data: Partial<Record<string, unknown>>;
    };
    consent?: boolean;
}

export const initialResalePaymentMethodValues: IResalePaymentMethodFormValues = {
    paymentMethod: {
        paymentMethodId: '',
        data: {},
    },
};

function getPartialResalePaymentMethodValidationSchema(
    fields: IResaleAdditionalField[],
    t: ReturnType<typeof useTranslations<'ResalePaymentMethodForm'>>
) {
    const schema: Record<string, AnySchema> = {};
    fields.forEach((field) => {
        schema[field.name] = field.isRequired
            ? Yup.string().required(t.fields.additional.error.required)
            : Yup.string();
    });
    return schema;
}

interface IGetResalePaymentMethodInitialValues {
    values: IResalePaymentMethodFormValues;
    paymentMethodProviders: IResalePaymentMethodProvider[];
    hasConsent?: boolean;
}

export function getResalePaymentMethodInitialValues({
    values,
    paymentMethodProviders,
    hasConsent,
}: IGetResalePaymentMethodInitialValues): IResalePaymentMethodFormValues {
    const initialValues = hasConsent ? { ...values, consent: false } : values;
    if (!values.paymentMethod.paymentMethodId) return initialValues;
    const selectedMethod = getSelectedPaymentMethod(
        values.paymentMethod.paymentMethodId,
        paymentMethodProviders
    );
    const dataFields = selectedMethod?.fields;
    if (!dataFields || !dataFields.length) {
        return {
            ...initialValues,
            paymentMethod: {
                ...initialValues.paymentMethod,
                data: {},
            },
        };
    }
    return {
        ...initialValues,
        paymentMethod: {
            ...initialValues.paymentMethod,
            data: dataFields.reduce((acc, field) => {
                acc[field.name] = '';
                return acc;
            }, {} as Record<string, string>),
        },
    };
}
