export default function getPrice(value: number) {
    const formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
        currencyDisplay: 'symbol',
        maximumFractionDigits: value % 1 === 0 ? 0 : 2,
    });

    return formatter.format(value);
}
