import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IValidateResaleMutation } from '../../models/resale.model';
import { initialResaleItemsValues, IResaleItemsFormValues } from '../../formik/resale-items.form';
import {
    initialResaleConditionValues,
    IResaleConditionFormValues,
} from '../../formik/resale-condition.form';
import {
    initialResaleShipmentMethodValues,
    IResaleShipmentMethodFormValues,
} from '../../formik/resale-shipment-method.form';
import {
    initialResalePaymentMethodValues,
    IResalePaymentMethodFormValues,
} from '../../formik/resale-payment-method.form';

export type TResaleStateStepMode = 'view' | 'hidden' | 'edit';

export interface IResaleState {
    steps: {
        items: {
            mode: TResaleStateStepMode;
            isComplete: boolean;
            values: IResaleItemsFormValues;
        };
        condition: {
            mode: TResaleStateStepMode;
            isComplete: boolean;
            values: IResaleConditionFormValues;
        };
        shipmentMethod: {
            mode: TResaleStateStepMode;
            isComplete: boolean;
            values: IResaleShipmentMethodFormValues;
        };
        paymentMethod: {
            mode: TResaleStateStepMode;
            isComplete: boolean;
            values: IResalePaymentMethodFormValues;
        };
    };
    mutation: IValidateResaleMutation;
}

const initialState: IResaleState = {
    steps: {
        items: {
            mode: 'edit',
            isComplete: false,
            values: initialResaleItemsValues,
        },
        condition: {
            mode: 'hidden',
            isComplete: false,
            values: initialResaleConditionValues,
        },
        shipmentMethod: {
            mode: 'hidden',
            isComplete: false,
            values: initialResaleShipmentMethodValues,
        },
        paymentMethod: {
            mode: 'hidden',
            isComplete: false,
            values: initialResalePaymentMethodValues,
        },
    },
    mutation: {
        items: [],
        paymentMethod: {},
        shipmentMethod: {},
    },
};

export const resaleSlice = createSlice({
    name: 'resale',
    initialState,
    reducers: {
        setResaleProducts: (state, { payload }: PayloadAction<IResaleItemsFormValues>) => {
            state.steps.items.values = payload;
            state.steps.items.mode = 'view';
            state.steps.items.isComplete = true;
            if (!state.steps.condition.isComplete) {
                state.steps.condition.mode = 'edit';
                return;
            }
        },
        setResaleMutation: (
            state,
            {
                payload,
            }: PayloadAction<{
                step: keyof IResaleState['steps'];
                mutation: IValidateResaleMutation;
            }>
        ) => {
            const { step, mutation } = payload;
            state.mutation = mutation;
            if (step === 'items') {
                // timestamp has been added to ensure that after
                // changing the mutation on the 'products' step,
                // the formik of the 'condition' step is reinitialized
                state.steps.condition.values.timestamp = new Date().getTime();
                state.steps.condition.values.items = mutation.items;
            }
            if (step === 'condition') {
                state.steps.items.values.items = mutation.items.map((item) => item.resaleItemUuid);
            }
        },
        setResaleStepMode: (
            state,
            {
                payload,
            }: PayloadAction<{ mode: TResaleStateStepMode; step: keyof IResaleState['steps'] }>
        ) => {
            state.steps[payload.step].mode = payload.mode;
        },
        setResaleStepValues: (
            state,
            { payload }: PayloadAction<{ step: keyof IResaleState['steps']; values: any }>
        ) => {
            const { step, values } = payload;
            state.steps[step].mode = 'view';
            state.steps[step].isComplete = true;
            state.steps[step].values = values;

            if (step === 'items') {
                state.steps.condition.isComplete = false;
            }

            if (!state.steps.condition.isComplete) {
                state.steps.condition.mode = 'edit';
                state.steps.paymentMethod.mode = 'hidden';
                return;
            }
            if (!state.steps.shipmentMethod.isComplete) {
                state.steps.shipmentMethod.mode = 'edit';
                return;
            }
            if (!state.steps.paymentMethod.isComplete) {
                state.steps.paymentMethod.mode = 'edit';
                return;
            }
        },
        resetResaleStep: (state, { payload }: PayloadAction<keyof IResaleState['steps']>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.steps[payload] = initialState.steps[payload];
        },
        resetResale: (state) => {
            state.steps = initialState.steps;
            state.mutation = initialState.mutation;
        },
    },
});

export default resaleSlice.reducer;

export const {
    setResaleStepMode,
    setResaleMutation,
    setResaleProducts,
    resetResaleStep,
    setResaleStepValues,
    resetResale,
} = resaleSlice.actions;

export const selectResaleState = (state: RootState) => state.resale;
