import * as Yup from 'yup';
import { AnySchema } from 'yup';
import IFormField from '../models/form-field.model';
import { IResaleAdditionalField, IResaleShipmentMethodProvider } from '../models/resale.model';
import useTranslations from '../hooks/use-translations';

export function getResaleShipmentMethodsField(
    shipmentMethodProviders: IResaleShipmentMethodProvider[]
): IFormField {
    const options: IFormField['options'] = shipmentMethodProviders.map((provider) => {
        return {
            label: `<p>${provider.name}<br>${provider.finalPrice.grossDisplay}${
                provider.shippingTime ? ` • ${provider.shippingTime}` : ''
            }</p>`,
            value: `${provider.shipmentMethodId}`,
            enabled: true,
        };
    });
    return {
        type: 'radio',
        name: 'shipmentMethod.shipmentMethodId',
        label: '',
        options: options,
    };
}

export function getResaleShipmentMethodProviderAdditionalFields(
    shipmentMethodProvider: IResaleShipmentMethodProvider
): (IFormField & { columnSpan?: string })[] {
    const { fields } = shipmentMethodProvider;
    if (!fields) return [];
    return fields.map((field) => {
        return {
            name: `shipmentMethod.data.${field.name}`,
            label: `${field.label}${field.isRequired ? '*' : ''}`,
            placeholder: field.placeholder || '',
            type: field.type,
            columnSpan: field.columnSpan,
        };
    });
}

export function getSelectedShipmentMethod(
    selectedShipmentMethodId: number | string | undefined,
    shipmentMethodProviders: IResaleShipmentMethodProvider[]
) {
    if (!selectedShipmentMethodId) return;
    return shipmentMethodProviders.find(
        (provider) => provider.shipmentMethodId.toString() === selectedShipmentMethodId.toString()
    );
}

interface IGetResaleShipmentMethodValidationSchemaConfig {
    selectedShipmentMethodProviderId?: number | string | undefined;
    shipmentMethodProviders: IResaleShipmentMethodProvider[];
    t: ReturnType<typeof useTranslations<'ResaleShipmentMethodForm'>>;
}

export function getResaleShipmentMethodValidationSchema({
    selectedShipmentMethodProviderId,
    shipmentMethodProviders,
    t,
}: IGetResaleShipmentMethodValidationSchemaConfig) {
    const selectedShipmentMethod = getSelectedShipmentMethod(
        selectedShipmentMethodProviderId,
        shipmentMethodProviders
    );
    const dataFields = selectedShipmentMethod?.fields;
    return Yup.object().shape({
        shipmentMethod: Yup.object({
            shipmentMethodId: Yup.string().required(t.fields.shipmentMethodId.error.required),
            data:
                dataFields && dataFields.length > 0
                    ? Yup.object(getPartialResaleShipmentMethodValidationSchema(dataFields, t))
                    : Yup.object(),
        }),
    });
}

export interface IResaleShipmentMethodFormValues {
    shipmentMethod: {
        shipmentMethodId: string;
        data: Partial<Record<string, unknown>>;
    };
}

export const initialResaleShipmentMethodValues: IResaleShipmentMethodFormValues = {
    shipmentMethod: {
        shipmentMethodId: '',
        data: {},
    },
};

function getPartialResaleShipmentMethodValidationSchema(
    fields: IResaleAdditionalField[],
    t: ReturnType<typeof useTranslations<'ResaleShipmentMethodForm'>>
) {
    const schema: Record<string, AnySchema> = {};
    fields.forEach((field) => {
        schema[field.name] = field.isRequired
            ? Yup.string().required(t.fields.additional.error.required)
            : Yup.string();
    });
    return schema;
}

export function getResaleShipmentMethodInitialValues(
    values: IResaleShipmentMethodFormValues,
    shipmentMethodProviders: IResaleShipmentMethodProvider[]
): IResaleShipmentMethodFormValues {
    if (!values.shipmentMethod.shipmentMethodId) return values;
    const selectedMethod = getSelectedShipmentMethod(
        values.shipmentMethod.shipmentMethodId,
        shipmentMethodProviders
    );
    const dataFields = selectedMethod?.fields;
    if (!dataFields || !dataFields.length) {
        return {
            ...values,
            shipmentMethod: {
                ...values.shipmentMethod,
                data: {},
            },
        };
    }
    return {
        ...values,
        shipmentMethod: {
            ...values.shipmentMethod,
            data: dataFields.reduce((acc, field) => {
                acc[field.name] = '';
                return acc;
            }, {} as Record<string, string>),
        },
    };
}
