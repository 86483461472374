import * as Yup from 'yup';

import { IResaleMutationItem } from '../models/resale.model';
import type useTranslations from '../hooks/use-translations';

export function getResaleConditionValidationSchema(
    t: ReturnType<typeof useTranslations<'ResaleConditionForm'>>
) {
    return Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                conditionId: Yup.string().required(t.items.conditionId.error.required),
                media: Yup.array()
                    .min(1, t.items.media.error.min)
                    .max(5, t.items.media.error.max)
                    .required(t.items.media.error.required),
            })
        ),
    });
}

export interface IResaleConditionFormValues {
    timestamp?: number;
    items: IResaleMutationItem[];
}

export const initialResaleConditionValues: IResaleConditionFormValues = {
    items: [],
};
