import * as Yup from 'yup';

import type useTranslations from '../hooks/use-translations';

export function getResaleItemsValidationSchema(
    t: ReturnType<typeof useTranslations<'ResaleItemsForm'>>
) {
    return Yup.object().shape({
        items: Yup.array().min(1, t.items.errors.min).required(t.items.errors.required),
    });
}

export interface IResaleItemsFormValues {
    items: string[];
}

export const initialResaleItemsValues: IResaleItemsFormValues = {
    items: [],
};
